import { Controller } from "stimulus";

export default class extends Controller {
  save(event) {
    event.preventDefault();
  }
  reply(event) {
    event.preventDefault();
  }

  edit(event) {
    event.preventDefault();
  }
}
