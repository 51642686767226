import { Controller } from "stimulus";

export default class extends Controller {
  share() {
    event.preventDefault();
    $("#modal-dialog").modal();
    //$(".sidebar > div").addClass("d-none");
    //$(".sidebar > #share-object").removeClass("d-none");
    //$(".sidebar").removeClass("d-none");
  }
}
