// Import the plugins
const Uppy = require("@uppy/core");
const XHRUpload = require("@uppy/xhr-upload");
const AwsS3Multipart = require("@uppy/aws-s3-multipart");
const ProgressBar = require("@uppy/progress-bar");
const FileInput = require("@uppy/file-input");
const DragDrop = require("@uppy/drag-drop");

// And their styles (for UI plugins)
require("@uppy/core/dist/style.css");
require("@uppy/progress-bar/dist/style.css");
require("@uppy/file-input/dist/style.css");
require("@uppy/drag-drop/dist/style.css");

const randomstring = require("randomstring");

export default class FileConsole {
  constructor(consoleElement) {
    this.element = $(consoleElement);
  }

  error(message) {
    this.element.html(message);
    this.setState("warning");
  }

  info(message) {
    this.element.html(message);
    this.setState("info");
  }

  success(message) {
    this.element.html(message);
    this.setState("success");
  }

  hide() {
    var el = this.element;
    setTimeout(function () {
      el.addClass("d-none");
    }, 3000);
  }

  setState(state) {
    this.element.removeClass("alert-success");
    this.element.removeClass("alert-danger");
    this.element.removeClass("alert-warning");
    this.element.removeClass("alert-info");
    this.element.removeClass("d-none");
    this.element.addClass("alert-" + state);
  }
}

const singleFileUpload = (fileInput) => {
  const formGroup = fileInput.parentNode;
  const form = $(fileInput).parents("form");
  const fileConsole = new FileConsole(
    document.getElementById(fileInput.dataset.uploadFileConsole)
  );

  formGroup.removeChild(fileInput);

  const uppy = fileUpload(fileInput);

  uppy
    .use(FileInput, {
      target: formGroup,
      locale: { strings: { chooseFiles: "Choose file" } },
    })
    .use(ProgressBar, {
      target: document.querySelector(".for-ProgressBar"),
      fixed: false,
      hideAfterFinish: false,
    });

  $(document).on("click", ".cancel-upload", function () {
    uppy.cancelAll();
    fileConsole.error("<p>Upload cancelled.</p>");
    form.find("input[type='submit']").attr("disabled", false);
    fileConsole.hide();
  });

  uppy.on("file-added", (file) => {
    fileConsole.info(
      "<p>Uploading file. <a class='btn btn-sm btn-secondary cancel-upload'>cancel</a></p>"
    );
    form.find("input[type='submit']").attr("disabled", true);
  });

  uppy.on("restriction-failed", (file, error) => {
    fileConsole.error(
      "<p>There is was a problem with the file you are trying to upload. " +
        error.toString() +
        "</p>"
    );
  });

  uppy.on("error", (file) => {
    fileConsole.error(
      "<p>There was a problem uploading this file. Please try again. If the problem persists please <a href='https://www.soundspace.studio/contact'>contact us</a>.</p>"
    );
    form.find("input[type='submit']").attr("disabled", true);
  });

  uppy.on("upload-success", (file, response) => {
    const fileData = uploadedFileData(file, response, fileInput);

    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
    const hiddenInput = document.getElementById(
      fileInput.dataset.uploadResultElement
    );
    hiddenInput.value = fileData;
    const fileDataJson = JSON.parse(fileData);
    $(".uppy-ProgressBar").removeClass("d-none");
    fileConsole.success(
      "<p><strong>" +
        fileDataJson.metadata.filename +
        "</strong> uploaded. You can now add your new track</p>"
    );

    if (
      form.find("#project_name").length != 0 &&
      form.find("#project_name").val() == ""
    ) {
      form.find("#project_name").val(file.name);
    }

    form.find("input[type='submit']").attr("disabled", false);
    form.submit();
  });
};

const multipleFileUpload = (fileInput) => {
  const formGroup = fileInput.parentNode;
  const form = $(fileInput).parents("form");
  const fileConsole = new FileConsole(
    document.getElementById(fileInput.dataset.uploadFileConsole)
  );
  fileConsole.hide();

  formGroup.removeChild(fileInput);

  const uppy = fileUpload(fileInput);

  uppy
    .use(DragDrop, { target: ".for-DragDrop", height: "200px" })
    .use(ProgressBar, {
      target: document.querySelector(".for-ProgressBar"),
      fixed: false,
      hideAfterFinish: false,
    });

  uppy.on("file-added", (file) => {
    form.find(".for-DragDrop").addClass("d-none");
    fileConsole.info(
      "<p>Uploading file. <a class='btn btn-sm btn-secondary cancel-upload'>cancel</a></p>"
    );
    form.find("input[type='submit']").attr("disabled", true);
  });

  uppy.on("upload-success", (file, response) => {
    const fileData = uploadedFileData(file, response, fileInput);
    const fileDataJson = JSON.parse(fileData);
    let newId = randomstring.generate();
    var newDocument = $("#document-upload-template .document").clone();
    var input = newDocument.find("input[name='name']");
    input.attr("name", `project[documents_attributes][${newId}][name]`);
    input.attr("value", fileDataJson.metadata.filename);
    input = newDocument.find("input[name='attachment']");
    input.attr("name", `project[documents_attributes][${newId}][attachment]`);
    input.attr("value", fileData);
    $("#document-upload-list").append(newDocument);
  });

  uppy.on("complete", (file, response) => {
    console.log("complete");
    $(".uppy-ProgressBar").removeClass("d-none");
    fileConsole.success(
      "<p><strong>All files</strong> have been uploaded. Click on <strong>Add Files</strong> to save them.</p>"
    );
    form.find("input[type='submit']").attr("disabled", false);
    uppy.reset();
  });

  uppy.on("error", (file) => {
    console.log("error");
    fileConsole.error(
      "<p>There was a problem uploading this file. Please try again. If the problem persists please <a href='https://www.moshimoshi.studio/contact'>contact us</a>.</p>"
    );
    form.find("input[type='submit']").attr("disabled", true);
  });
};

const fileUpload = (fileInput) => {
  const uppy = Uppy({
    id: fileInput.id,
    autoProceed: true,
    restrictions: {
      allowedFileTypes: fileInput.accept.split(","),
      maxFileSize: 524288000,
      maxNumberOfFiles: fileInput.multiple == true ? null : 1,
    },
  });

  uppy.use(AwsS3Multipart, {
    companionUrl: "/", // will call uppy-s3_multipart endpoint mounted on `/s3/multipart`
  });

  return uppy;
};

const uploadedFileData = (file, response, fileInput) => {
  if (fileInput.dataset.uploadServer == "s3") {
    const id = file.meta["key"].match(/^cache\/(.+)/)[1]; // object key without prefix

    return JSON.stringify(fileData(file, id));
  } else if (fileInput.dataset.uploadServer == "s3_multipart") {
    const id = response.uploadURL.match(/\/cache\/([^\?]+)/)[1]; // object key without prefix

    return JSON.stringify(fileData(file, id));
  } else {
    return JSON.stringify(response.body);
  }
};

// constructs uploaded file data in the format that Shrine expects
const fileData = (file, id) => ({
  id: id,
  storage: "cache",
  metadata: {
    size: file.size,
    filename: file.name,
    mime_type: file.type,
  },
});

// Use 'DOMContentLoaded' event if not using Turbolinks
document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll("input[type=file]").forEach((fileInput) => {
    if (fileInput.multiple) {
      console.log("multiple file upload");
      multipleFileUpload(fileInput);
    } else {
      console.log("single file upload");
      singleFileUpload(fileInput);
    }
  });
});
