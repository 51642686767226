import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    var hueb = new Huebee(".color-input", {
      notation: "hex",
      saturations: 2,
    });
  }
}
