// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('wavesurfer.js')
global.Huebee = require('huebee/dist/huebee.pkgd')
var jQuery = require('jquery')
var moment = require('moment')
window.autosize = global.autosize = require('autosize/dist/autosize')
require('webpack-jquery-ui/effects')
window.SVGInjector = global.SVGInjector = require('svg-injector/dist/svg-injector.min')
require('users')
window.jQuery = global.jQuery = global.$ = jQuery
window.moment = global.moment = moment
global.Lightbox = require('ekko-lightbox')
import '../stylesheets/application'
import './daterangepicker.min'
import './bootstrap_custom.js'
import './waveform.js'
import './template.js'
import './uploads.js'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'controllers'

var Trix = require('trix')
require('@rails/actiontext')

Trix.config.blockAttributes.heading1.tagName = 'h3'

$(document).on('click', '[data-toggle="lightbox"]', function (event) {
  event.preventDefault()
  $(this).ekkoLightbox({ alwaysShowClose: true })
})

document.addEventListener('turbolinks:load', function () {
  $('[data-toggle="tooltip"]').tooltip()
  $('input[name="daterange"]').daterangepicker({
    autoUpdateInput: false,
    locale: {
      cancelLabel: 'Clear',
      format: 'YYYY/MM/DD',
    },
  })
  $('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.startDate.format('YYYY/MM/DD') + ' - ' + picker.endDate.format('YYYY/MM/DD'))
  })
})
