import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["list"];

  reply(event) {
    event.preventDefault();
    var parentComment = $(event.target)
      .parents(".comment")
      .first();

    var replies = parentComment.find(".replies").first();
    if (replies.find(".comment-form").length == 0) {
      let container = $(event.target)
        .parents(".comment")
        .first()
        .attr("id");
      replies.prepend($("#templates #comment-form").html());
      let reply = replies.find(".comment-form").first();
      reply
        .find("form #comment_parent_id")
        .val(parentComment.attr("id").replace("comment_", ""));
      reply.addClass("reply");
      reply[0].scrollIntoView();
    }
  }

  edit(event) {
    event.preventDefault();
    var messageContent = $(event.target)
      .parents(".comment")
      .first()
      .find(".message-content");
    messageContent
      .find(".body-wrapper")
      .first()
      .addClass("d-none");
    messageContent
      .find("form")
      .first()
      .removeClass("d-none");
  }

  inlineCancel(event) {
    event.preventDefault();
    $(event.target)
      .parents("form")[0]
      .reset();
    $(event.target)
      .parents("form")
      .addClass("d-none");
    $(event.target)
      .parents(".message-content")
      .find(".body-wrapper")
      .removeClass("d-none");
  }

  cancel(event) {
    event.preventDefault();
    if (
      $(event.target)
        .parents("form")
        .find("#comment_region_id")
        .val()
        .match("wavesurfer")
    ) {
      window.App.waveform.wavesurfer.regions.list[
        $(event.target)
          .parents("form")
          .find("#comment_region_id")
          .val()
      ].remove();
    }
    $(event.target)
      .parents(".comment-form")
      .remove();
  }

  onPostInlineSuccess(event) {
    let [data, status, xhr] = event.detail;
    $(event.target)
      .parents(".comment")
      .first()
      .replaceWith(xhr.response);
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    var targetNode = null;
    var commentForm = $(event.target)
      .parents(".comment-form")
      .first();
    if (this.isReply(commentForm)) {
      targetNode = commentForm.parents(".replies").first();
      var element = targetNode.prepend(xhr.response);
    } else {
      targetNode = $(this.listTarget);
      targetNode
        .parent()
        .find(".zero-state")
        .addClass("d-none");
      let regionStart = parseFloat(
        commentForm.find("#comment_region_start").val()
      );
      if (Number.isNaN(regionStart)) {
        var element = targetNode.append(xhr.response);
        $(this.listTarget)
          .children()
          .last()[0]
          .scrollIntoView();
      } else {
        var selectedElement = null;
        for (var comment of targetNode.find(".comment")) {
          if (parseFloat(comment.dataset.regionStart) > regionStart) {
            selectedElement = comment;
            break;
          }
        }
        if (selectedElement == null) {
          targetNode.append(xhr.response);
        } else {
          $(xhr.response).insertBefore(selectedElement);
        }
        let regionId = commentForm.find("#comment_region_id").val();
        var newComment = document.querySelector(
          '[data-region-id="' + regionId + '"]'
        );
        newComment.scrollIntoView();
        $(newComment)
          .find(".body-wrapper")
          .effect("highlight", {}, 1000);
      }
    }
    $(event.target)
      .parents(".comment-form")
      .remove();
  }

  isReply(comment) {
    return comment.hasClass("reply");
  }
}
