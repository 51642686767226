$(document).ready(function() {
  $(document).on("click", ".toggle-forgot-password-form", function() {
    $("#forgot-password-form").removeClass("d-none");
    $("#login-form").addClass("d-none");
  });

  $(document).on("click", ".toggle-login-form", function() {
    $("#forgot-password-form").addClass("d-none");
    $("#login-form").removeClass("d-none");
  });
});
