import { Controller } from 'stimulus'
import axios from 'axios'
import autocomplete from 'autocomplete.js'
import Rails from '@rails/ujs'
import Tagify from '@yaireo/tagify'

let tagify = null
let searchUrl = null

export default class extends Controller {
  static targets = ['email', 'message', 'errorMessage']

  invite() {
    event.preventDefault()
    let error = false
    fetch(this.data.get('url'), {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'X-CSRF_Token': Rails.csrfToken(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:
        'email=' +
        encodeURIComponent(this.emailTarget.value) +
        '&message=' +
        encodeURIComponent(this.messageTarget.value),
    })
      .then((response) => {
        if (!response.ok) {
          error = true
        }
        return response.text()
      })
      .then((html) => {
        if (error) {
          this.errorMessageTarget.classList.remove('d-none')
          this.errorMessageTarget.innerText = html
        } else {
          this.errorMessageTarget.classList.add('d-none')
          $('#members').prepend(html)
          $('[data-toggle="tooltip"]').tooltip()
          tagify.removeAllTags()
        }
      })
  }

  changeRole(event) {
    event.preventDefault()
    const membershipId =
      event.target.dataset.membershipId || event.target.parentElement.dataset.membershipId
    const role = event.target.dataset.role || event.target.parentElement.dataset.role
    let error = false
    fetch(`/memberships/${membershipId}?role=${role}`, {
      method: 'put',
      credentials: 'same-origin',
      headers: {
        'X-CSRF_Token': Rails.csrfToken(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        if (!response.ok) {
          error = true
        }
        return response.text()
      })
      .then((html) => {
        if (error) {
          console.log('error in role')
          this.errorMessageTarget.classList.remove('d-none')
          this.errorMessageTarget.innerText = html
        } else {
          console.log('updated role')
          $(`#membership_${membershipId}`).replaceWith(html)
          $('[data-toggle="tooltip"]').tooltip()
          this.errorMessageTarget.classList.add('d-none')
        }
      })
  }

  remove(event) {
    event.preventDefault()
    let error = false
    fetch(
      '/memberships/' +
        (event.target.dataset.membershipId || event.target.parentElement.dataset.membershipId),
      {
        method: 'delete',
        credentials: 'same-origin',
        headers: {
          'X-CSRF_Token': Rails.csrfToken(),
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          error = true
        }
        return response.text()
      })
      .then((html) => {
        if (error) {
          this.errorMessageTarget.classList.remove('d-none')
          this.errorMessageTarget.innerText = html
        } else {
          this.errorMessageTarget.classList.add('d-none')
          $(
            '#membership_' +
              (event.target.dataset['membershipId'] ||
                $(event.target).parent()[0].dataset['membershipId'])
          ).remove()
        }
      })
  }

  copyShareLink(event) {
    event.preventDefault()
    let urlInput = $('#share-link-url')
    urlInput.select()
    document.execCommand('copy')
    this.errorMessageTarget.innerText = 'Share Link Copied!'
    this.errorMessageTarget.classList.remove('d-none')
  }

  generateShareLink(event) {
    event.preventDefault()
    let error = false
    fetch(event.target.dataset['generateUrl'], {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'X-CSRF_Token': Rails.csrfToken(),
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          error = true
        }
        return response.json()
      })
      .then((json) => {
        if (error) {
          this.errorMessageTarget.classList.remove('d-none')
          this.errorMessageTarget.innerText = json['message']
        } else {
          this.errorMessageTarget.classList.add('d-none')
          let key = json['key']
          let urlInput = $('#share-link-url')
          urlInput.parents('.input-group').removeClass('d-none')
          event.target.classList.add('d-none')
          urlInput.val(document.location.origin + '/share_links/' + json['key'])
          this.errorMessageTarget.innerText = 'Share Link created!'
          this.errorMessageTarget.classList.remove('d-none')
        }
      })
  }

  source() {
    const url = this.data.get('search-url')
    return (query, callback) => {
      axios.get(url, { params: { query } }).then((response) => {
        callback(response.data)
      })
    }
  }

  connect() {
    let controller
    searchUrl = this.data.get('search-url')
    tagify = new Tagify(this.emailTarget, {
      templates: {
        tag: function (tagData) {
          console.log(tagData)
          try {
            return `<tag title='${
              tagData.value
            }' contenteditable='false' spellcheck="false" class='tagify__tag ${
              tagData.class ? tagData.class : ''
            }' ${this.getAttributes(tagData)}>
                        <x title='remove tag' class='tagify__tag__removeBtn'></x>
                        <div>
                            <span class='tagify__tag-text'>${tagData.value}</span>
                        </div>
                    </tag>`
          } catch (err) {}
        },
        dropdownItem: function (tagData) {
          console.log(tagData)
          try {
            return `<div class='tagify__dropdown__item ${
              tagData.class ? tagData.class : ''
            }' tagifySuggestionIdx="${tagData.tagifySuggestionIdx}">
                            <span>${tagData.value}</span>
                        </div>`
          } catch (err) {}
        },
      },
      whitelist: [],
      dropdown: {
        enabled: 3,
        classname: 'extra-properties', // <- custom classname for this dropdown, so it could be targeted
      },
    })

    // listen to any keystrokes which modify tagify's input
    tagify.on('input', this.onInput)
  }
  async onInput(e) {
    let value = e.detail.value
    tagify.settings.whitelist.length = 0 // reset the whitelist

    // show loading animation and hide the suggestions dropdown
    tagify.loading(true).dropdown.hide.call(tagify)

    fetch(searchUrl + '?query=' + value)
      .then((RES) => RES.json())
      .then(function (whitelist) {
        // update inwhitelist Array in-place
        tagify.settings.whitelist.splice(0, whitelist.length, ...whitelist)
        tagify.loading(false).dropdown.show.call(tagify, value) // render the suggestions dropdown
      })
  }
}
