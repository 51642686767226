import { Controller } from "stimulus";

export default class extends Controller {
  destroy(event) {
    event.preventDefault();
  }

  removeUpload(event) {
    event.preventDefault();
    console.log("in remove upload");
    $(event.target)
      .parents(".document")
      .remove();
  }

  cancelUpload(event) {
    event.preventDefault();
    $("#document-form").addClass("d-none");
  }
}
