import { Controller } from 'stimulus'

export default class extends Controller {
  showNotes(event) {
    event.preventDefault()
    $('#track-asset-controls .asset-actions').addClass('d-none')
    $('#track-asset-controls .documents-controls').addClass('d-none')
    $('#track-asset-controls .notes-controls').removeClass('d-none')
  }

  showDocuments(event) {
    event.preventDefault()
    $('#track-asset-controls .asset-actions').addClass('d-none')
    $('#track-asset-controls .documents-controls').removeClass('d-none')
    $('#track-asset-controls .notes-controls').addClass('d-none')
  }

  showNotations(event) {
    event.preventDefault()
    $('#track-asset-controls .asset-actions').addClass('d-none')
  }

  newNote(event) {
    event.preventDefault()
    if ($('#notes .comment-form').length === 0) {
      $('#notes').prepend($('#templates #comment-form').html())
      const scrollTo = $('#notes .comment-form')
      scrollTo[0].scrollIntoView()
    }
  }

  newDocument(event) {
    event.preventDefault()
    $('#document-form').removeClass('d-none')
    const documents = $('#documents')
    $('#document-form').find('.for-DragDrop').removeClass('d-none')
    $('#document-upload-list').html('')
    documents[0].scrollToTop()
  }
}
