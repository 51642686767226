var WaveSurfer = require('wavesurfer.js')
import Regions from 'wavesurfer.js/src/plugin/regions'
import Minimap from 'wavesurfer.js/src/plugin/minimap'
import Timeline from 'wavesurfer.js/src/plugin/timeline'
import Cursor from 'wavesurfer.js/src/plugin/cursor'
import Rails from '@rails/ujs'

let SCROLL_SPEED = 100

export default class WaveForm {
  constructor() {
    this.wavesurfer = WaveSurfer.create({
      container: document.querySelector('#waveform'),
      height: 80,
      scrollParent: true,
      normalize: true,
      splitChannels: false,
      backend: 'MediaElement',
      plugins: [
        Timeline.create({
          container: '#waveform-timeline',
        }),
        Regions.create(),
        Cursor.create(),
        Minimap.create({
          height: 30,
          waveColor: '#ddd',
          progressColor: '#999',
          container: '#waveform-minimap',
          showRegions: true,
        }),
      ],
    })

    this.cachedPeaks = null
    this.mediaId = this.wavesurfer.container.dataset.mediaId
    this.duration = this.wavesurfer.container.dataset.mediaDuration
    var error = false
    fetch('/media/' + this.mediaId + '/peaks', {
      credentials: 'same-origin',
      headers: {
        'X-CSRF_Token': $('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          error = true
        }
        return response.json()
      })
      .then((json) => {
        if (error) {
        } else {
          if (json != null) {
            this.cachedPeaks = json.map((item) => parseFloat(item))
            this.usedPeaks = true
            this.wavesurfer.load(this.wavesurfer.container.dataset.url, this.cachedPeaks)
            this.wavesurfer.fireEvent('waveform-ready')
          } else {
            this.wavesurfer.load(this.wavesurfer.container.dataset.url)
          }
        }
      })

    this.wavesurfer.on('waveform-ready', this.onReady.bind(this))
    this.wavesurfer.on('region-click', this.navigateToComment.bind(this))
    this.wavesurfer.on('region-update-end', this.editRegion.bind(this))
    this.wavesurfer.on('region-updated', this.saveRegions.bind(this))
    this.wavesurfer.on('region-removed', this.saveRegions.bind(this))
    this.wavesurfer.on('region-in', this.showNote.bind(this))
    this.wavesurfer.on('ready', this.saveDuration.bind(this))

    this.wavesurfer.on(
      'region-play',
      function (region) {
        region.once('out', function () {
          this.wavesurfer.play(region.start)
          this.wavesurfer.pause()
        })
      }.bind(this)
    )

    this.setupListeners()
  }

  onReady() {
    var color = document.body.dataset.annotationColor
    if (color == '' || color == undefined) {
      color = this.randomColor(0.25)
    } else {
      color = this.hex2rgba(color, 0.25)
    }
    if (document.body.dataset.canComment == 'true') {
      this.wavesurfer.enableDragSelection({
        color: color,
      })
    }
    this.loadRegions(App.regionData)
    this.wavesurfer.minimap.renderRegions()
    $('.media-wrapper .loading').addClass('d-none')
    if (this.cachedPeaks == null) {
      this.savePeaks()
    }
  }

  saveDuration() {
    var error = false
    console.log(this.duration)

    if (this.duration == '' || this.duration == undefined) {
      fetch('/media/' + this.mediaId + '/save_duration', {
        method: 'post',
        credentials: 'same-origin',
        headers: {
          'X-CSRF_Token': $('meta[name="csrf-token"]').attr('content'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          duration: this.wavesurfer.getDuration(),
        }),
      })
        .then((response) => {
          if (!response.ok) {
            error = true
          }
          return response.text()
        })
        .then((html) => {
          return
        })
    }
  }

  savePeaks() {
    var error = false
    var peaks = null
    var nominalWidth = Math.round(
      this.wavesurfer.getDuration() *
        this.wavesurfer.params.minPxPerSec *
        this.wavesurfer.params.pixelRatio
    )
    this.wavesurfer.exportPCM(nominalWidth, 10000000, true).then((result) => {
      fetch('/media/' + this.mediaId + '/save_peaks.json', {
        method: 'post',
        credentials: 'same-origin',
        headers: {
          'X-CSRF_Token': $('meta[name="csrf-token"]').attr('content'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          peaks: result,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            error = true
          }
          return response.text()
        })
        .then((html) => {
          return
        })
    })
  }

  /**
   * Save annotations to localStorage.
   */
  saveRegions() {
    localStorage.regions = JSON.stringify(
      Object.keys(this.wavesurfer.regions.list).map(
        function (id) {
          var region = this.wavesurfer.regions.list[id]
          return {
            start: region.start,
            end: region.end,
            attributes: region.attributes,
            data: region.data,
          }
        }.bind(this)
      )
    )
  }

  /**
   * Load regions from localStorage.
   */
  loadRegions(regions) {
    regions.forEach(
      function (region) {
        if (region.region_start == undefined || region.region_end == undefined) return
        region.color = region.annotation_color
        if (region.color == '' || region.color == undefined) {
          region.color = this.randomColor(0.25)
        } else {
          region.color = this.hex2rgba(region.color, 0.25)
        }
        this.wavesurfer.addRegion({
          id: region.id,
          start: region.region_start,
          end: region.region_end,
          color: region.color,
          data: { note: region.body, user_id: region.user_id },
          drag: region.user_id == document.body.dataset.userId,
          resize: region.user_id == document.body.dataset.userId,
        })
      }.bind(this)
    )
  }

  hex2rgba(hex, alpha = 1) {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
    return `rgba(${r},${g},${b},${alpha})`
  }

  /**
   * Random RGBA color.
   */
  randomColor(alpha) {
    return (
      'rgba(' +
      [~~(Math.random() * 255), ~~(Math.random() * 255), ~~(Math.random() * 255), alpha || 1] +
      ')'
    )
  }

  navigateToComment(region) {
    $("#track-assets-nav a[href='#notations-tab-content']").tab('show')
    var existingComment = document.querySelector('[data-region-id="' + region.id + '"]')
    existingComment.scrollIntoView()
    $(existingComment).find('.body-wrapper').effect('highlight', {}, 1000)
  }

  /**
   * Edit annotation for a region.
   */
  editRegion(region) {
    if (region.data.user_id == null || region.data.user_id == document.body.dataset.userId) {
      var existingComment = document.querySelector('[data-region-id="' + region.id + '"]')
      var form = null
      if (existingComment) {
        form = $(existingComment).find('form')[0]
        ;(form.elements.comment_region_start.value = Math.round(region.start * 100) / 100),
          (form.elements.comment_region_end.value = Math.round(region.end * 100) / 100)
        form.elements.comment_region_id.value = region.id
        form.dataset.region = region.id
        Rails.fire(form, 'submit')
      } else {
        if ($('#comments .comment-form').length == 0) {
          let container = $('#notes')
          $('#comments').prepend($('#templates #comment-form').html())
          let scrollTo = $('#comments .comment-form')
          scrollTo[0].scrollIntoView()
        }
        form = $('#comments form')[0]
        ;(form.elements.comment_region_start.value = Math.round(region.start * 100) / 100),
          (form.elements.comment_region_end.value = Math.round(region.end * 100) / 100)
        form.elements.comment_region_id.value = region.id
        form.dataset.region = region.id
      }
    }
  }

  /**
   * Display annotation.
   */
  showNote(region) {
    // Make sure this.hash has a value before overriding default behavior
    let hash = '#' + $('.comment[data-region-id=' + region.id + ']').attr('id')

    // Using jQuery's animate() method to add smooth page scroll
    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
    $('html, body').animate(
      {
        scrollTop: $(hash).offset().top,
      },
      SCROLL_SPEED,
      function () {
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash
      }
    )
  }

  setupListeners() {
    this.wavesurfer.on('play', function () {
      document.querySelector('[data-action="play"]').classList.add('d-none')
      document.querySelector('[data-action="pause"]').classList.remove('d-none')
    })
    this.wavesurfer.on('pause', function () {
      document.querySelector('[data-action="play"]').classList.remove('d-none')
      document.querySelector('[data-action="pause"]').classList.add('d-none')
    })
    $(document).on(
      'click',
      '[data-action="play"]',
      function (element) {
        this.wavesurfer.play()
        mixpanel.track('Track Play')
      }.bind(this)
    )
    $(document).on(
      'click',
      '[data-action="pause"]',
      function (element) {
        this.wavesurfer.pause()
      }.bind(this)
    )
    $(document).on(
      'click',
      '[data-action="skip-back"]',
      function (element) {
        this.wavesurfer.skipBackward()
      }.bind(this)
    )
    $(document).on(
      'click',
      '[data-action="skip-forward"]',
      function (element) {
        this.wavesurfer.skipForward()
      }.bind(this)
    )
    $(document).on(
      'click',
      '[data-action="mute"]',
      function (element) {
        document.querySelector('[data-action="mute"]').classList.add('d-none')
        document.querySelector('[data-action="unmute"]').classList.remove('d-none')
        this.currentVolume = this.wavesurfer.getVolume()
        this.wavesurfer.setMute(true)
      }.bind(this)
    )
    $(document).on(
      'click',
      '[data-action="unmute"]',
      function (element) {
        document.querySelector('[data-action="mute"]').classList.remove('d-none')
        document.querySelector('[data-action="unmute"]').classList.add('d-none')
        this.wavesurfer.setMute(false)
        this.wavesurfer.setVolume(this.currentVolume)
      }.bind(this)
    )
    $(document).on(
      'click',
      '[data-action="skip-to-region"]',
      function (element) {
        this.wavesurfer.pause()
        this.wavesurfer.regions.list[element.target.dataset.regionId].play()
      }.bind(this)
    )
    $(document).on(
      'click',
      '[data-action="edit-region"]',
      function (element) {
        this.editRegion(this.wavesurfer.regions.list[element.target.dataset.regionId])
      }.bind(this)
    )

    let playbackSpeeds = document.querySelectorAll('[data-action="playback-speed"]')
    playbackSpeeds.forEach(
      function (element) {
        element.addEventListener(
          'click',
          function (element) {
            this.wavesurfer.setPlaybackRate(element.target.dataset.speed)
            document.querySelector('.playback-speed').textContent =
              element.target.dataset.speed + 'x'
          }.bind(this)
        )
      }.bind(this)
    )
  }
}

document.addEventListener('turbolinks:load', function () {
  if (document.querySelector('#waveform') != null) {
    window.App = window.App || {}
    if (document.getElementById('waveform')) {
      document.getElementById('waveform').textContent = ''
    }
    window.App.waveform = new WaveForm()
  }
})
